'use client'

import * as React from 'react'
import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useAuth } from '@features/profile/hooks/useProfile'
import { useQuery } from '@tanstack/react-query'

export function IntercomWrapper({ children }: { children: React.ReactNode }) {
  const { boot, shutdown, update } = useIntercom()

  const { data: user, isLoading } = useQuery({
    queryKey: ['authData'],
    queryFn: async () => {
      const res = await fetch('/api/auth')
      const data = await res.json()
      return data
    },
  })

  useEffect(() => {
    boot({
      hideDefaultLauncher: true,
    })

    if (!isLoading && user?.userId) {
      update({
        userId: user.userId,
        email: user.email,
      })
    }

    return () => {
      shutdown()
    }
  }, [shutdown, boot, update, user, isLoading])

  return <>{children}</>
}
