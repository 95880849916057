import * as z from 'zod'
import { IdSchema } from '../schemas'
import { buildObjectModel } from '../tools/buildObjectModel'

const ROADBLOCK_KEY = 'rb'

const entities = z.enum(['team', 'org', 'need', 'view'])

export const TeamReferral = z.object({
  entity: z.literal(entities.enum.team),
  method: z.enum(['join', 'invite', 'leave', 'give']),
  params: z.object({ teamId: IdSchema }),
})

export const OrgReferral = z.object({
  entity: z.literal(entities.enum.org),
  method: z.enum(['follow', 'unfollow', 'give']),
  params: z.object({ orgId: IdSchema }),
})

export const NeedReferral = z.object({
  entity: z.literal(entities.enum.need),
  method: z.enum(['create-checkout']),
  params: z.object({ needId: IdSchema }),
})

export const ViewReferral = z.object({
  entity: z.literal(entities.enum.view),
  method: z.enum(['view']),
  params: z.object({
    pathname: z.string(),
    query: z
      .record(z.union([z.string(), z.number(), z.boolean(), z.null()]))
      .optional(),
  }),
})

export const Referral = z
  .discriminatedUnion('entity', [
    TeamReferral,
    OrgReferral,
    NeedReferral,
    ViewReferral,
  ])
  .describe('Referral')

export type Referral = z.infer<typeof Referral>

const transformer = (referral: z.infer<typeof Referral>) => ({
  ...referral,
  get redirectUrl() {
    const entity = referral.entity
    const sp = new URLSearchParams({
      src: ROADBLOCK_KEY,
      method: referral.method,
    })
    const extraparam = referral.method == 'give' ? '/give' : ''
    switch (entity) {
      case 'need':
        return `/${entity}/${referral.params.needId}?${sp}`
      case 'team':
        return `/${entity}/${referral.params.teamId}${extraparam}?${sp}`
      case 'org':
        return `/${entity}/${referral.params.orgId}${extraparam}?${sp}`
      case 'view':
        return `/${referral.params.pathname}?${sp}`
      default: {
        const _exhaustiveCheck: never = entity
        return _exhaustiveCheck
      }
    }
  },
  get data() {
    return referral
  },
  get signupRelationships() {
    return {
      org: referral.entity === 'org' ? referral.params.orgId : undefined,
      team: referral.entity === 'team' ? referral.params.teamId : undefined,
    }
  },
})

export const ReferralBuilder = buildObjectModel(Referral, transformer)
