var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"HWKmQ7pJgxLum0E6k2QUW"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import {
  addEventProcessor,
  browserProfilingIntegration,
  browserTracingIntegration,
  init,
  replayIntegration,
} from '@sentry/nextjs'
import { PURPOSITY_ENV } from 'app-constants'
import { ZodProcessor, defaultOptions } from './sentry.common'

init({
  ...defaultOptions,
  integrations: [
    browserTracingIntegration(),
    browserProfilingIntegration(),
    replayIntegration(),
  ],

  replaysOnErrorSampleRate:
    PURPOSITY_ENV === 'development' ? 0 : PURPOSITY_ENV === 'preview' ? 1 : 0.1,
  replaysSessionSampleRate:
    PURPOSITY_ENV === 'development' ? 0 : PURPOSITY_ENV === 'preview' ? 1 : 0.1,
})

addEventProcessor(ZodProcessor)
