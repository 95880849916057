'use client'

import { env } from 'env'
import * as React from 'react'
import { IntercomProvider as BaseIntercomProvider } from 'react-use-intercom'

export function IntercomProvider({ children }: { children: React.ReactNode }) {
  return (
    <BaseIntercomProvider appId={env.NEXT_PUBLIC_INTERCOM_APP_ID}>
      {children}
    </BaseIntercomProvider>
  )
}
